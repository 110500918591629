import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import routesMap from '../../Routes';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { Link } from './myAccountStyledComponents';
import PopupShareReferralCode from './PopupShareReferralCode';

import iconEdit from '../../assets/order/iconEdit.svg';
import iconSend from '../../assets/myAccount/iconSend.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 41.75%;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 31px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin-bottom: 18px;
    padding-bottom: 10px;
    padding-top: 36px;
  }
`;

const Img = styled.img`
  width: ${props => props.large ? '25px' : '12px'};
  height: ${props => props.large ? '15px' : '13px'};
  ${props => props.large && 'margin: 0px 8px;'}
  cursor: pointer;
`;

const P = styled.p`
  margin: 0px 0px 13px;
  color: ${colors.lightGrey4};
  ${({ bold }) => bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px 9px;
  }
`;

class InfosSummary extends React.Component {
  constructor() {
    super();
    this.state = { isSharePopupOpened: false };
    this.toggleIsSharePopupOpened = this.toggleIsSharePopupOpened.bind(this);
  }

  toggleIsSharePopupOpened() {
    this.setState({ isSharePopupOpened: !this.state.isSharePopupOpened });
  }

  renderCustomerInfos() {
    const { customer } = this.props.customerContext;
    if (!customer) return <P>Aucune information renseignée</P>;
    const { firstname, lastname, email, referrerCode } = customer;
    return (
      <div>
        <P bold>{firstname} {lastname}</P>
        <P>{email}</P>
        <P>
          Mon code parrain : {referrerCode}
          <Img
            large src={iconSend} alt="Partager votre code parrain"
            onClick={() => this.toggleIsSharePopupOpened()}
          />
        </P>
      </div>
    );
  }

  render() {
    const { isSharePopupOpened } = this.state;
    const { customer } = this.props.customerContext;
    return (
      <Container>
        <TitleContainer>
          Mes informations
          <Link to={routesMap.MyInfos.url}>
            <Img
              src={iconEdit}
              alt={'Editer mon profil'}
            />
          </Link>
        </TitleContainer>
        {this.renderCustomerInfos()}
        {isSharePopupOpened &&
          <PopupShareReferralCode
            closePopup={() => this.toggleIsSharePopupOpened()}
            referralCode={customer.referrerCode}
          />
        }
      </Container>
    );
  }
}

InfosSummary.propTypes = {
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(InfosSummary);
