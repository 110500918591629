import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../home/v3/styledComponents';
import { Link } from './myAccountStyledComponents';


const Ul = styled.ul`
  position: absolute;
  top: 115px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 237px;
  display: ${({ isDisplayed }) => isDisplayed ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  background-color: ${colors.white};
  color: ${colors.navy};
  z-index: 2;
  text-align: center;
  overflow:hidden;
  list-style-type: none;
  padding: 0px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  box-shadow: 0px 4px 8px ${colors.myAccountMenu};
`;

const Li = styled.li`
  width: 175px;
  ${({ borderBottom }) => borderBottom && `border-bottom: 0.5px solid ${colors.lightGrey2};`}
  padding: 19px 0px;
  &:hover {
    opacity: 0.7;
  }
`;

const DropDownList = ({ dropDownList, isDisplayed, selectedAccountCategory, close }) => {
  const list = dropDownList.filter(({ title }) => title !== selectedAccountCategory);
  const lastIndex = list[list.length - 1].index;
  return (
    <Ul isDisplayed={isDisplayed}>
      {list.map(({ title, index, url }) => (
        <Li
          key={index}
          borderBottom={index !== lastIndex}
          onClick={close}
        >
          <Link to={url}>
            {title}
          </Link>
        </Li>
      ))}
    </Ul>
  );
};

DropDownList.propTypes = {
  dropDownList: PropTypes.arrayOf(PropTypes.object),
  isDisplayed: PropTypes.bool,
  selectedAccountCategory: PropTypes.string,
  close: PropTypes.func.isRequired,
};

DropDownList.defaultProps = {
  dropDownList: [],
  isDisplayed: false,
  selectedAccountCategory: undefined,
};

export default DropDownList;
