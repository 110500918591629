import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect from 'react-select';

import momentFr from '../../tilli-components/src/config/moment.fr';
import {
  mobileThresholdPixels, colors,
  FormInput as FormInputV2,
} from '../home/v3/styledComponents';
import { setTheme } from '../order/orderStyledComponents';

import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';
import sendIcon from '../../assets/myAccount/iconSend.svg';

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 918px;
  align-self: center;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 314px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const FormInputContainer = styled.div`
  display: ${({ noDisplay }) => noDisplay ? 'none' : 'flex'};
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
  width: ${({ size }) => size === 'small' ? '45%' : '100%'};
  ${({ errorBirthday }) => errorBirthday && 'width: calc(100% + 10px);'}
  margin-bottom: 21px;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ mobileBorderBottom }) => mobileBorderBottom && `
      border-bottom: 0.5px solid ${colors.lightGrey2};
      padding-bottom: 36px;
      margin-bottom: 25px;
    `}
  }
`;

const FormInput = styled(FormInputV2)`
  margin: 0px 0px ${({ error }) => error === undefined ? '0' : '-10'}px;
  width: calc(100% - 16px);
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  ${({ isAvailable }) => !isAvailable && `pointer-events: none; cursor: unset; color: ${colors.lightGrey4};`}
`;

const MoreInfos = styled.p`
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 0px;
  color: ${colors.red};
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 17px;
    font-size: 12px;
  }
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const InputAndImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: ${({ birthday }) => birthday ? '3' : '93'}%;
  top: ${({ birthday }) => birthday ? '15' : '-14'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    left: ${({ birthday }) => birthday ? '3' : '92'}%;
  }
`;

const ISend = styled.i`
  background-image: url(${sendIcon});
  background-size: contain;
  width: 25px;
  height: 18.73px;
  position: relative;
  left: 88%;
  top: -27px;
  cursor: pointer;
`;

const SelectsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Select = styled(ReactSelect)`
  ${({ margin }) => margin && 'margin: 0px 7px; flex-grow: 1;'}
  ${({ small }) => small && 'width: 20%; min-width: 64px;'}
  ${({ medium }) => medium && 'width: 25%; min-width: 91px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ small }) => small && 'min-width: 58px;'}
    ${({ medium }) => medium && 'min-width: 78px;'}
  }
`;

const customStyles = {
  control: base => ({
    ...base,
    borderColor: colors.navy,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
    borderRadius: '2px',
    paddingLeft: '5px',
    boxShadow: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${colors.navy}`,
    },
  }),
  singleValue: base => ({
    ...base,
    color: colors.navy,
  }),
  dropdownIndicator: base => ({
    ...base,
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: 'none',
  }),
};

const dates = (() => {
  const arrDates = [];
  for (let i = 1; i < 32; i += 1) {
    const day = i.toString().length === 1 ? `0${i.toString()}` : i.toString();
    arrDates.push({ value: day, label: day });
  }
  return arrDates;
})();

const months = momentFr.months.map(month => ({ value: month, label: month }));

const years = (() => {
  const date = new Date();
  const yearNow = date.getFullYear();
  const arrYears = [];
  for (let i = 0; i < 100; i += 1) {
    const year = (yearNow - i).toString();
    arrYears.push({ value: year, label: year });
  }
  return arrYears;
})();

const InfosForm = ({ inputsList, checkFormInputValue, setFormInputValue, setBirthday, toggleIsSharePopupOpened }) => {
  const columnsList = Object.values(inputsList).reduce((acc, input, index) => {
    const newAcc = [...acc];
    newAcc[index < 5 ? 0 : 1].list.push(input);
    return [...newAcc];
  }, [{ id: 0, list: [] }, { id: 1, list: [] }]);
  return (
    <FormContainer>
      {columnsList.map(({ id, list }) => (
        <Column key={`column_${id}`}>
          {list.map((
            { name, label, isActive, value, type, error, date, month, year, isAvailable },
          ) => {
            if (name.includes('error')) {
              return (
                <FormInputContainer noDisplay={!isActive} key={label} size={'large'}>
                  <MoreInfos>
                    {label}
                  </MoreInfos>
                </FormInputContainer>
              );
            }
            return (
              <FormInputContainer
                key={name}
                errorBirthday={error !== undefined && name === 'birthday'}
                mobileBorderBottom={id === 1 && name === 'confirmationPassword'}
              >
                <Label>{label}</Label>
                {name !== 'birthday' &&
                  <InputAndImgContainer>
                    <FormInput
                      name={name} value={value}
                      type={type || 'text'}
                      onBlur={() => checkFormInputValue(name)}
                      onChange={({ target }) => setFormInputValue(target)}
                      error={error}
                      isAvailable={isAvailable !== false}
                    />
                    {error !== undefined && <I error={error} />}
                    {name === 'referrerCode' && <ISend onClick={toggleIsSharePopupOpened} />}
                  </InputAndImgContainer>
                }
                {name === 'birthday' &&
                  <SelectsContainer>
                    <Select
                      placeholder=""
                      value={date}
                      onChange={valueSelected => setBirthday(valueSelected, 'date')}
                      options={dates}
                      styles={customStyles}
                      theme={theme => setTheme(theme)}
                      small
                    />
                    <Select
                      placeholder=""
                      value={month}
                      onChange={valueSelected => setBirthday(valueSelected, 'month')}
                      options={months}
                      styles={customStyles}
                      theme={theme => setTheme(theme)}
                      margin
                    />
                    <Select
                      placeholder=""
                      value={year}
                      onChange={valueSelected => setBirthday(valueSelected, 'year')}
                      options={years}
                      styles={customStyles}
                      theme={theme => setTheme(theme)}
                      medium
                    />
                    {error !== undefined && <I birthday error={error} />}
                  </SelectsContainer>
                }
              </FormInputContainer>
            );
          })}
        </Column>
      ))}
    </FormContainer>
  );
};

InfosForm.propTypes = {
  inputsList: PropTypes.shape({}).isRequired,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
  setBirthday: PropTypes.func.isRequired,
  toggleIsSharePopupOpened: PropTypes.func,
};

InfosForm.defaultProps = {
  toggleIsSharePopupOpened: () => {},
};

export default InfosForm;
