import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import { registerCard } from '../../services/mangopay';
import callApi from '../../services/api';
import { pushToLayer } from '../../services/googleTagManager';
import checkZipcodeError, { getLocality } from '../../services/zipcode';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import { PageContainer, Title } from './myAccountStyledComponents';
import CreditCard from '../order/CreditCard';

import iconEdit from '../../assets/order/iconEdit.svg';
import iconGarbage from '../../assets/order/iconGarbage.svg';
import logoMastercard from '../../assets/order/mastercard.png';
import logoVisa from '../../assets/order/visa.png';
import logoMangoPay from '../../assets/Mangopay2.png';
import logoAxa from '../../assets/axa.png';
import acceptedCBs from '../../assets/cb.png';
import AddressForm from './AddressForm';

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 47.75%;
  ${({ transparent }) => transparent && 'opacity: 0.2; pointer-events: none; cursor: unset;'}
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 30px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const P = styled.p`
  margin: ${({ noMargin }) => noMargin ? '0px' : '0px 0px 13px'};
  color: ${({ navy }) => navy ? colors.navy : colors.lightGrey4};
  ${({ bold }) => bold && 'font-weight: 500;'}
  ${({ underline }) => underline && 'text-decoration: underline; cursor: pointer;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ noMargin }) => noMargin ? '0px' : '0px 0px 9px'};
  }
`;

const AddressContainer = styled.div`
padding-bottom: 10px;
border-bottom: 0.5px solid ${colors.lightGrey2};
${({ noMargin }) => !noMargin && 'margin-bottom: 28px;'}
${({ transparent }) => transparent && 'opacity: 0.2; pointer-events: none; cursor: unset;'}
@media (max-width: ${mobileThresholdPixels}) {
  ${({ mobileMarginBottom }) => mobileMarginBottom
    ? 'margin-bottom: 18px; padding-bottom: 50px;'
    : 'border-bottom: 0px;'
}
}
`;

const CardContainer = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.lightGrey4};
  border-bottom: 0.5px solid ${colors.lightGrey2};
  padding: ${({ largePadding }) => largePadding ? '34px 0px 23px;' : '22px 0px;'}
  ${({ transparent }) => transparent && 'opacity: 0.2; pointer-events: none; cursor: unset;'}
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ largePadding }) => largePadding && 'padding: 18px 0px 19px; border-bottom: 0px;'}
  }
`;

const CardSubContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImgCard = styled.img`
  width: 43px;
  margin-right: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 36px;
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color:${colors.lightGrey4};
  margin: 0px 0.5px;
  ${props => props.marginRight && 'margin-right: 5px;'}
`;

const FirstLineAddress = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.img`
  margin-left: ${({ largeMarginLeft }) => largeMarginLeft ? '23' : '10'}px;
  height: 14px;
  cursor: pointer;
`;

const Button = styled(ButtonV3)`
margin-top: ${({ small }) => small ? '0' : '52'}px;
width: ${({ small }) => small ? '177' : '206'}px;
font-size: 14px;
line-height: 16px;
align-self: center;
${({ marginBottom }) => marginBottom && 'margin-bottom: 25px;'}
${props => props.isAvailable === false && `
  background-color: ${colors.lightGrey3};
  border-color: ${colors.lightGrey3};
  color: ${colors.white};
  pointer-events: none;
  cursor: unset;`
}
@media (max-width: ${mobileThresholdPixels}) {
  min-width: unset;
  margin: 29px 0px ${({ small }) => small ? '21' : '82'}px;
}
`;

const CreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 18px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 52px 0px 49px;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    justify-content: center;
    margin: 4px 0px 17px;
  }
`;

const GuaranteesContainer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  color: ${colors.lightGrey4};
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;

const Logo = styled.img`
  height: 17px;
  width: auto;
  margin-right: ${({ marginRight }) => marginRight}px;
  ${({ marginLeft }) => marginLeft && 'margin-left: 5px; height: 39px;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: ${({ marginRight }) => marginRight - 6}px;
    ${({ marginLeft }) => marginLeft && 'height: 30px;'}
  }
`;

const IconsContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
`;

const scrollToTop = () => {
  if (typeof window === 'undefined') return;
  window.scrollTo(0, 0);
};

const deleteCard = (id, deleteCustomerCard) => (
  callApi(`customer/me/cards/${id}`, 'delete', {})
    .then(() => {
      deleteCustomerCard(id);
    })
    .catch(() => null)
);

const deleteAddress = (id, deleteCustomerAddress) => (
  callApi(`customer/me/addresses/${id}`, 'delete', {})
    .then(() => {
      deleteCustomerAddress(id);
    })
    .catch(() => null)
);

const renderCustomerAddresses = (
  customer, showAddressForm, deleteCustomerAddress, openAddressEdition,
) => {
  if (!customer || !customer.addresses || customer.addresses.length === 0) {
    return (
      <AddressContainer transparent={showAddressForm} noMargin>
        <P>Aucune adresse renseignée</P>
      </AddressContainer>);
  }

  return customer.addresses.map(({ street, comment, zipcode, locality, name, _id }, index) => (
    <AddressContainer mobileMarginBottom key={_id} transparent={showAddressForm}>
      <FirstLineAddress>
        <P bold={!!name} navy={!!name}>{name || street}</P>
        {!showAddressForm && (
          <IconsContainer>
            <Icon
              src={iconEdit} alt={'Editer l\'adresse'}
              onClick={() => openAddressEdition(index)}
            />
            <Icon
              src={iconGarbage} alt={'Supprimer l\'adresse'}
              onClick={() => deleteAddress(_id, deleteCustomerAddress)}
            />
          </IconsContainer>
        )}
      </FirstLineAddress>
      {!!name && <P>{street}</P>}
      {comment && <P>{comment}</P>}
      <P>{zipcode} {locality}</P>
    </AddressContainer>
  ));
};

const renderCustomerCards = (customer, isAddNewCardSelected, deleteCustomerCard) => {
  if (customer === undefined || !customer.cards || customer.cards.length === 0) {
    return (
      <CardContainer transparent={isAddNewCardSelected} largePadding>
        <P noMargin>Aucune carte bancaire renseignée</P>
      </CardContainer>
    );
  }
  return customer.cards.map(({ cardProvider, alias, expirationDate, id }) => (
    <CardContainer transparent={isAddNewCardSelected} key={id}>
      <CardSubContainer>
        <ImgCard
          src={cardProvider === 'MASTERCARD' ? logoMastercard : logoVisa}
          alt={`${cardProvider} logo`}
        />
        <Dot /><Dot /><Dot /><Dot marginRight />{alias.slice(-4)}
      </CardSubContainer>
      <CardSubContainer>
        {`${expirationDate.slice(0, 2)}/20${expirationDate.slice(-2)}`}
        <Icon
          src={iconGarbage} alt={'Supprimer la carte'}
          largeMarginLeft
          onClick={() => deleteCard(id, deleteCustomerCard)}
        />
      </CardSubContainer>
    </CardContainer>
  ));
};

const getAddressInputs = () => ({
  name: { value: '', name: 'name', label: "Nom de l'adresse", isRequired: false, size: 'large' },
  street: { value: '', name: 'street', label: 'Adresse (voie)', isRequired: true, size: 'large' },
  comment: {
    value: '',
    name: 'comment',
    label: "Complément d'adresse",
    isRequired: false,
    size: 'large',
    // eslint-disable-next-line max-len
    more: 'Indiquez le bâtiment, l’étage, le code et toutes les indications qui pourront faciliter l’accès à notre couturier.' },
  zipcode: {
    value: '',
    name: 'zipcode',
    label: 'Code postal',
    isRequired: true,
    size: 'small',
  },
  locality: { value: '', name: 'locality', label: 'Ville', isRequired: true, size: 'small' },
  errorZipcode: { name: 'errorZipcode', isActive: false, label: 'Ville pas encore desservie :(' },
});

const getInitialState = () => ({
  addressForm: getAddressInputs(),
  isAddNewCardSelected: false,
  showAddressForm: false,
  cardName: '',
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  expiryMonth: '',
  cardId: '',
  indexAddressToEdit: undefined,
});

class AddressesAndPayments extends React.Component {
  constructor() {
    super();
    this.state = getInitialState();
    this.setIsAddNewCardSelected = this.setIsAddNewCardSelected.bind(this);
    this.renderCreditCardForm = this.renderCreditCardForm.bind(this);
    this.setCreditCardInputValue = this.setCreditCardInputValue.bind(this);
    this.submitCard = this.submitCard.bind(this);
    this.setAddressInputValue = this.setAddressInputValue.bind(this);
    this.checkAddressInputValue = this.checkAddressInputValue.bind(this);
    this.addAddress = this.addAddress.bind(this);
    this.openAddressEdition = this.openAddressEdition.bind(this);
    this.hideAddressFormAndResetForm = this.hideAddressFormAndResetForm.bind(this);
  }

  setCreditCardInputValue(input, value) {
    this.setState({ [input]: value });
  }

  setIsAddNewCardSelected(isAddNewCardSelected) {
    this.setState({ isAddNewCardSelected });
  }

  setAddressInputValue(target) {
    const { name, value } = target;
    const addressForm = { ...this.state.addressForm };
    if (name === 'zipcode') {
      addressForm.locality.value = getLocality(value) || '';
    }
    addressForm[name].value = value;
    this.setState({ addressForm: { ...addressForm } });
  }

  hideAddressFormAndResetForm() {
    this.setState({
      addressForm: getAddressInputs(),
      showAddressForm: false,
      indexAddressToEdit: undefined,
    });
  }

  openAddressEdition(index) {
    const { customerContext: { customer } } = this.props;
    const { name, street, zipcode, locality, comment } = customer.addresses[index];
    const addressForm = getAddressInputs();
    addressForm.name.value = name || '';
    addressForm.street.value = street;
    addressForm.street.isUnavailable = true;
    addressForm.zipcode.value = zipcode;
    addressForm.zipcode.error = false;
    addressForm.zipcode.isUnavailable = true;
    addressForm.locality.value = locality;
    addressForm.locality.isUnavailable = true;
    addressForm.comment.value = comment || '';
    this.setState({
      addressForm: { ...addressForm },
      showAddressForm: true,
      indexAddressToEdit: index,
    });
    scrollToTop();
  }

  checkAddressInputValue(name) {
    const addressForm = { ...this.state.addressForm };
    if (name === 'zipcode') {
      const message = checkZipcodeError(addressForm.zipcode.value);
      addressForm.errorZipcode.isActive = message !== '';
      addressForm.zipcode.error = message !== '';
      if (addressForm.locality.error === undefined && addressForm.locality.value !== '') {
        addressForm.locality.error = false;
      } else if (addressForm.locality.error !== undefined) {
        addressForm.locality.error = addressForm.locality.value === '';
      }
    } else if (addressForm[name].isRequired) {
      addressForm[name].error = addressForm[name].value === '';
    } else {
      addressForm[name].error = addressForm[name].value === '' ? undefined : false;
    }
    this.setState({ addressForm: { ...addressForm } });
  }

  submitCard() {
    const { customerContext: { customer, addCustomerCard } } = this.props;
    const { expiryMonth, expiryYear, cvv, cardNumber, cardId } = this.state;

    const expiryDate = `${expiryMonth}${expiryYear}`;
    const cardData = { expiryDate, cvv, cardNumber, cardId };

    return registerCard(cardData, customer._id)
      .then(card => callApi('public/submitCard', 'post', { card }))
      .then(({ card }) => {
        addCustomerCard(card);
        this.setState({ ...getInitialState() }, () => scrollToTop());
        pushToLayer({
          event: 'Card Saved',
          context: 'post_order',
        });
      });
  }

  addAddress() {
    const { customerContext: { addCustomerAddress } } = this.props;
    const { addressForm: { name, street, comment, zipcode, locality } } = this.state;
    const address = {
      name: name.value,
      street: street.value,
      comment: comment.value,
      zipcode: zipcode.value,
      locality: locality.value,
    };
    return callApi('public/me/addresses', 'post', { address })
      .then(({ newAddress }) => {
        addCustomerAddress(newAddress);
        this.setState({ ...getInitialState() }, () => scrollToTop);
      });
  }

  editAddress() {
    const { customerContext: { customer, editCustomerAddress } } = this.props;
    const { addressForm: { name, comment }, indexAddressToEdit } = this.state;
    const addressId = customer.addresses[indexAddressToEdit]._id; // eslint-disable-line
    return callApi(`customer/me/addresses/${addressId}`, 'put', { name: name.value, comment: comment.value })
      .then((newCustomer) => {
        editCustomerAddress(newCustomer.addresses);
        this.setState({ ...getInitialState() }, () => scrollToTop);
      });
  }

  renderCreditCardForm() {
    const { cardName, cardNumber, cvv } = this.state;
    return (
      <CreditCardForm>
        <CreditCard
          onChange={this.setCreditCardInputValue}
          cardName={cardName}
          cardNumber={cardNumber}
          cvv={cvv}
          isCvvBottom
        />
        <GuaranteesContainer>
          Nous acceptons :
          <Logo marginLeft src={acceptedCBs} alt="Moyens de paiement acceptés" />
        </GuaranteesContainer>
        <GuaranteesContainer>
          <Logo marginRight={12} src={logoMangoPay} alt="Logo MangoPay" />
          Règlement 100% sécurisé via MangoPay
        </GuaranteesContainer>
        <GuaranteesContainer>
          <Logo marginRight={15} src={logoAxa} alt="Logo Axa" />
          Vos vêtements sont garantis par notre partenaire AXA
        </GuaranteesContainer>
        <ButtonsContainer>
          <Button navy small onClick={this.submitCard}>Valider</Button>
          <P noMargin underline onClick={() => this.setIsAddNewCardSelected(false)}>Annuler</P>
        </ButtonsContainer>
      </CreditCardForm>
    );
  }

  render() {
    const { customerContext: { customer, deleteCustomerCard, deleteCustomerAddress } } = this.props;
    const { isAddNewCardSelected, showAddressForm, addressForm, indexAddressToEdit } = this.state;

    const isDone = Object.values(addressForm)
      .reduce((acc, formInput) =>
        acc && (!formInput.isRequired
          || (formInput.name === 'zipcode' && formInput.error === false)
          || (formInput.name !== 'zipcode' && formInput.value !== ''))
      , true);

    return (
      <PageContainer row>
        <SubContainer transparent={isAddNewCardSelected}>
          <Title marginBottom>Mes adresses</Title>
          {showAddressForm &&
            <div>
              <AddressForm
                inputsList={addressForm}
                checkFormInputValue={this.checkAddressInputValue}
                setFormInputValue={this.setAddressInputValue}
              />
              <ButtonsContainer>
                <Button
                  navy small isAvailable={isDone}
                  onClick={() => indexAddressToEdit !== undefined ? this.editAddress() : this.addAddress()}
                >
                  Valider
                </Button>
                <P
                  noMargin underline
                  onClick={this.hideAddressFormAndResetForm}
                >
                  Annuler
                </P>
              </ButtonsContainer>
            </div>
          }

          {renderCustomerAddresses(customer, showAddressForm, deleteCustomerAddress, this.openAddressEdition)}

          {!showAddressForm &&
            <Button marginBottom onClick={() => this.setState({ showAddressForm: true })}>Ajouter une adresse</Button>
          }
        </SubContainer>
        <SubContainer marginBottom transparent={showAddressForm}>
          <Title>Mes paiements</Title>
          {isAddNewCardSelected && this.renderCreditCardForm()}

          {renderCustomerCards(customer, isAddNewCardSelected, deleteCustomerCard)}

          {!isAddNewCardSelected &&
            <Button
              marginBottom
              onClick={() => this.setIsAddNewCardSelected(true)}
            >
              Ajouter un moyen de paiement
            </Button>
          }
        </SubContainer>
      </PageContainer>
    );
  }
}

AddressesAndPayments.propTypes = {
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({}),
    addCustomerCard: PropTypes.func,
    addCustomerAddress: PropTypes.func,
    editCustomerAddress: PropTypes.func,
    deleteCustomerCard: PropTypes.func,
    deleteCustomerAddress: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(AddressesAndPayments);
