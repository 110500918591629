import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { mobileThresholdPixels, colors } from '../home/v3/styledComponents';

export const PageContainer = styled.div`
  display: flex;
  ${({ row }) => !row ? 'flex-direction: column;' : 'justify-content: space-between;'}
  flex-grow: 1;
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;
  margin-left: 69px;
  margin-right: 116px;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    justify-content: unset;
    flex-grow: unset;
    font-size: 14px;
    margin: 0px 28px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  margin: 20px 0px ${({ marginBottom }) => marginBottom ? '34' : '0'}px;
  padding-bottom: 18px;
  border-bottom: 0.5px solid ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    justify-content: space-between;
    margin: 0px 0px ${({ marginBottom }) => marginBottom ? '18' : '0'}px;
    padding-bottom: 10px;
  }
`;

export const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${colors.navy};
`;
