import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import CardReactFormContainer from 'card-react';
import ReactTooltip from 'react-tooltip';
import { Form1, ErrorText } from '../styledComponents';
import {
  FormInput, mobileThresholdPixels,
} from '../home/v2/styledComponents';
import { colors } from '../home/v3/styledComponents';
import { setTheme } from './orderStyledComponents';
import helpIcon from '../../assets/boutonAide.jpg';
import cvvImg from '../../assets/CVV.png';

const months = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

const years = (() => {
  const date = new Date();
  const yearNow = date.getFullYear();
  const arrYears = [];
  for (let i = 0; i < 10; i += 1) {
    const year = (yearNow + i).toString();
    arrYears.push({ value: year, label: year });
  }
  return arrYears;
})();

const customStyles = {
  control: base => ({
    ...base,
    borderColor: colors.navy,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 300,
    borderRadius: '2px',
    paddingLeft: '5px',
    boxShadow: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${colors.navy}`,
    },
  }),
  singleValue: base => ({
    ...base,
    color: colors.navy,
  }),
};

const StyledFormInput = styled(FormInput)`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  height: 38px;
  margin: 0;
  padding: 0px 0px 0px 15px;
  border-radius: 2px;
  width: ${({ isCvvBottom, small }) => {
    if (!isCvvBottom) return small ? '80px' : '373px';
    return small ? '120px' : 'calc(100% - 17px)';
  }};
  ::placeholder {
    color: ${colors.darkBlueGrey};
  }
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
    width: ${props => props.small ? 'calc(50% - 23px)' : 'calc(100% - 15px)'};;
  }
`;

const FieldLabel = styled.p`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 20px;
  color: ${colors.navy};
  outline: none;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

const ExpiryDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

const StyledSelect = styled(Select)`
  width: ${({ isCvvBottom, small }) => (isCvvBottom || !small) ? '120' : '97'}px;
  margin-right: ${props => props.small ? '15' : '60'}px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(50% - 6px);
    margin-right: 0px;
  }
`;

const StyledImg = styled.img`
  margin-left: 15px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const TooltipText = styled.p`
  font-family: Roboto;
  font-weight: 300;
  font-size: 15px;
  color: ${colors.navy};
  width: 274px;
`;

const TooltipDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  box-shadow: 0px 10px 10px rgba(190, 190, 190, 0.5) !important;
`;

const Container = styled.div`
  margin-bottom: 10px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

const ExpiryDateCVVContainer = styled.div`
  display: flex;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const CVVContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
`;

class CreditCard extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedMonth: '',
      selectedYear: '',
    };
    this.handleExpiryDate = this.handleExpiryDate.bind(this);
  }

  handleExpiryDate(selectedValue, stateName, propName) {
    const { onChange } = this.props;
    onChange(propName, selectedValue.value);
    this.setState({ [stateName]: selectedValue });
  }

  render() {
    const { onChange, formError, cardName, cardNumber, cvv, error, isCvvBottom } = this.props;
    const { selectedMonth, selectedYear } = this.state;
    return (
      <Container>
        <CardReactFormContainer
          container="card-wrapper-2"
          formInputsNames={{
            number: 'cardNumber',
            expiry: 'CCexpiry',
            cvc: 'CCcvc',
            name: 'cardName',
          }}
          initialValues={{
            number: cardNumber,
            cvc: cvv,
            name: cardName,
          }}
          classes={{
            valid: 'valid-input', // optional — default 'jp-card-valid'
            invalid: 'invalid-input', // optional — default 'jp-card-invalid'
          }}
          formatting
        >
          <div id="card-wrapper-2" />
          <StyledReactTooltip place="top" type="light" effect="float" id="cvvTooltip">
            <TooltipText>
              Un cryptogramme visuel de carte est une série unique de trois ou quatre chiffres,
              imprimée sur la plupart des cartes de crédit.
            </TooltipText>
            <TooltipDiv>
              <img src={cvvImg} alt="Où trouver le CVV ?" />
            </TooltipDiv>
          </StyledReactTooltip>
          <Form1 marginTop="0px">
            <FieldLabel>Nom du titulaire de la carte</FieldLabel>
            <StyledFormInput
              name={'cardName'}
              placeholder={'Prénom Nom'}
              onChange={e => onChange('cardName', e.target.value)}
              isCvvBottom={isCvvBottom}
            />
            <FieldLabel>Numéro de la carte</FieldLabel>
            <StyledFormInput
              type="text" name="cardNumber"
              placeholder="XXXX XXXX XXXX XXXX"
              onChange={e => onChange('cardNumber', e.target.value)}
              error={formError && !cardNumber}
              isCvvBottom={isCvvBottom}
            />
            {error && <ErrorText>{error}</ErrorText>}
            <ExpiryDateCVVContainer>
              <div>
                <FieldLabel>{"Date d'expiration"}</FieldLabel>
                <ExpiryDateContainer>
                  <StyledSelect
                    small
                    placeholder="MM"
                    value={selectedMonth}
                    onChange={selectedVal => this.handleExpiryDate(selectedVal, 'selectedMonth', 'expiryMonth')}
                    options={months}
                    styles={customStyles}
                    theme={theme => setTheme(theme)}
                    isCvvBottom={isCvvBottom}
                  />
                  <StyledSelect
                    placeholder="AAAA"
                    value={selectedYear}
                    onChange={selectedVal => this.handleExpiryDate(selectedVal, 'selectedYear', 'expiryYear')}
                    options={years}
                    styles={customStyles}
                    theme={theme => setTheme(theme)}
                    isCvvBottom={isCvvBottom}
                  />
                  <input placeholder="MM/YY" type="text" name="CCexpiry" style={{ display: 'none' }} />
                  {/* we don't display the input container above because it is not the right format */}
                </ExpiryDateContainer>
              </div>
              {!isCvvBottom &&
                <div>
                  <FieldLabel>CVV</FieldLabel>
                  <CVVContainer>
                    <StyledFormInput
                      small
                      name={'CCcvc'}
                      placeholder={'XXX'}
                      onChange={e => onChange('cvv', e.target.value)}
                    />
                    <StyledImg src={helpIcon} alt="C'est quoi le CVV ?" data-tip data-for="cvvTooltip" />
                  </CVVContainer>
                </div>
              }
            </ExpiryDateCVVContainer>
            {isCvvBottom &&
              <div>
                <FieldLabel>CVV</FieldLabel>
                <CVVContainer>
                  <StyledFormInput
                    small
                    name={'CCcvc'}
                    placeholder={'XXX'}
                    onChange={e => onChange('cvv', e.target.value)}
                    isCvvBottom
                  />
                  <StyledImg src={helpIcon} alt="C'est quoi le CVV ?" data-tip data-for="cvvTooltip" />
                </CVVContainer>
              </div>
            }
          </Form1>
        </CardReactFormContainer>
      </Container>
    );
  }
}

CreditCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  formError: PropTypes.bool,
  cardName: PropTypes.string,
  cardNumber: PropTypes.string,
  cvv: PropTypes.string,
  error: PropTypes.string,
  isCvvBottom: PropTypes.bool,
};

CreditCard.defaultProps = {
  formError: false,
  cardName: '',
  cardNumber: '',
  cvv: '',
  error: '',
  isCvvBottom: false,
};

export default CreditCard;
