import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withCustomerContext from '../../withCustomerContext';
import routesMap from '../../Routes';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { Link } from './myAccountStyledComponents';

import iconEdit from '../../assets/order/iconEdit.svg';
import logoMastercard from '../../assets/order/mastercard.png';
import logoVisa from '../../assets/order/visa.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 41.75%;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    min-height: unset;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 31px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin-bottom: 18px;
    padding-bottom: 10px;
    padding-top: 36px;
  }
`;

const Img = styled.img`
  width: 12px;
  height: 13px;
  cursor: pointer;
`;

const P = styled.p`
  margin: ${({ noMargin }) => noMargin ? '0px' : '0px 0px 13px'};
  color: ${({ navy }) => navy ? colors.navy : colors.lightGrey4};
  ${({ bold }) => bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${({ noMargin }) => noMargin ? '0px' : '0px 0px 9px'};
  }
`;

const AddressContainer = styled.div`
  min-height: 179px;
  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    ${({ mobileMarginBottom }) => mobileMarginBottom && 'margin-bottom: 38px;'}
  }
`;

const CardContainer = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.lightGrey4};
  border-top: 0.5px solid ${colors.lightGrey2};
  border-bottom: 0.5px solid ${colors.lightGrey2};
  padding: 22px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 9px 0px 107px;
    padding: 18px 0px;
  }
`;

const CardNumberAndTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImgCard = styled.img`
  width: 43px;
  margin-right: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 36px;
  }
`;

const Dot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color:${colors.lightGrey4};
  margin: 0px 0.5px;
  ${props => props.marginRight && 'margin-right: 5px;'}
`;

const renderAddress = (street, comment, zipcode, locality, name) => (
  <AddressContainer mobileMarginBottom>
    {name && <P bold navy>{name}</P>}
    <P>{street}</P>
    {comment && <P>{comment}</P>}
    <P>{zipcode} {locality}</P>
  </AddressContainer>
);


const renderCustomerAddress = (customer) => {
  if (!customer || !customer.addresses || customer.addresses.length === 0) {
    return <AddressContainer><P>Aucune adresse renseignée</P></AddressContainer>;
  }

  const { street, comment, zipcode, locality, name } = customer.addresses[0];
  return renderAddress(street, comment, zipcode, locality, name);
};

const renderCustomerCard = (customer) => {
  if (!customer || !customer.cards || customer.cards.length === 0) {
    return <CardContainer><P noMargin>Aucune carte bancaire renseignée</P></CardContainer>;
  }
  const { cardProvider, alias, expirationDate } = customer.cards[0];
  return (
    <CardContainer>
      <CardNumberAndTypeContainer>
        <ImgCard
          src={cardProvider === 'MASTERCARD' ? logoMastercard : logoVisa}
          alt={`${cardProvider} logo`}
        />
        <Dot /><Dot /><Dot /><Dot marginRight />{alias.slice(-4)}
      </CardNumberAndTypeContainer>
      {`${expirationDate.slice(0, 2)}/20${expirationDate.slice(-2)}`}
    </CardContainer>
  );
};

const AddressesAndPaymentsSummary = ({ customerContext: { customer } }) => (
  <Container>
    <TitleContainer>
      {'Mes adresses & paiements'}
      <Link to={routesMap.MyAddressesAndPayments.url}>
        <Img
          src={iconEdit}
          alt={'Editer mes addresses et paiements'}
        />
      </Link>
    </TitleContainer>
    {renderCustomerAddress(customer)}
    {renderCustomerCard(customer)}
  </Container>
);

AddressesAndPaymentsSummary.propTypes = {
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(AddressesAndPaymentsSummary);
