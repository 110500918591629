import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { mobileThresholdPixels } from '../home/v3/styledComponents';
import { PageContainer } from './myAccountStyledComponents';
import Menu from './Menu';
import Summary from './Summary';
import MyOrders from './MyOrders';
import AddressesAndPayments from './AddressesAndPayments';
import Infos from './Infos';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SubContainer = styled.div`
  display: flex;
  width: 1440px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    flex-direction: column;
  }
`;

const MyAccount = ({ selectedAccountCategory }) => (
  <Container>
    <SubContainer>
      <Menu selectedAccountCategory={selectedAccountCategory} />
      {selectedAccountCategory === 'MyAccount' && <Summary />}
      {selectedAccountCategory === 'MyOrders' &&
        <PageContainer>
          <MyOrders />
        </PageContainer>
      }
      {selectedAccountCategory === 'MyAddressesAndPayments' && <AddressesAndPayments />}
      {selectedAccountCategory === 'MyInfos' && <Infos />}
    </SubContainer>
  </Container>
);

MyAccount.propTypes = {
  selectedAccountCategory: PropTypes.string.isRequired,
};

export default MyAccount;
