import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';

import withCustomerContext from '../../withCustomerContext';
import callApi from '../../services/api';
import { colors, mobileThresholdPixels, Button as ButtonV3 } from '../home/v3/styledComponents';
import { PageContainer, Title } from './myAccountStyledComponents';
import InfosForm from './InfosForm';
import PopupShareReferralCode from './PopupShareReferralCode';

import iconEdit from '../../assets/order/iconEdit.svg';
import iconSend from '../../assets/myAccount/iconSend.svg';

const Button = styled(ButtonV3)`
  margin-top: 0px;
  width: 177px;
  font-size: 14px;
  line-height: 16px;
  align-self: center;
  ${props => props.isAvailable === false && `
    background-color: ${colors.lightGrey3};
    border-color: ${colors.lightGrey3};
    color: ${colors.white};
    pointer-events: none;
    cursor: unset;`
}
  @media (max-width: ${mobileThresholdPixels}) {
    min-width: unset;
    margin: 11px 0px 37px;
  }
`;

const Img = styled.img`
  width: ${props => props.large ? '25px' : '12px'};
  height: ${props => props.large ? '15px' : '13px'};
  margin-left: ${props => props.large ? '8px' : '27px'};;
  cursor: pointer;
`;

const SummaryLine = styled.div`
  display: flex;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ mobileMarginBottom }) => mobileMarginBottom && 'margin-bottom: 20px;'}
  }
`;

const P = styled.p`
  margin: 0px ${({ bold }) => bold ? '5' : '0'}px 13px 0px;
  color: ${colors.lightGrey4};
  ${({ bold }) => bold && `font-weight: 500; color: ${colors.navy};`}
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px ${({ bold }) => bold ? '5' : '0'}px 9px 0px;
  }
`;

// eslint-disable-next-line max-len
const getInfosInputs = (firstname = '', lastname = '', email = '', birthday = undefined, phone = '', referrerCode = '') => ({
  firstname: { value: firstname, name: 'firstname', label: 'Prénom', error: firstname === '', isRequired: true },
  lastname: { value: lastname, name: 'lastname', label: 'Nom', error: lastname === '', isRequired: true },
  email: { value: email, name: 'email', label: 'Adresse mail', isAvailable: false, isRequired: true },
  birthday: {
    name: 'birthday',
    value: !birthday ? '' : moment(birthday),
    label: 'Date de naissance',
    isRequired: !!birthday,
    date: !birthday
      ? ''
      : { value: moment(birthday).format('DD'), label: moment(birthday).format('DD') },
    month: !birthday
      ? ''
      : { value: moment(birthday).format('MMMM'), label: moment(birthday).format('MMMM') },
    year: !birthday
      ? ''
      : { value: moment(birthday).format('YYYY'), label: moment(birthday).format('YYYY') },
  },
  phone: { value: phone, name: 'phone', label: 'Téléphone', error: phone === '', isRequired: true },
  password: { value: '', name: 'password', label: 'Mot de passe actuel', type: 'password' },
  newPassword: { value: '', name: 'newPassword', label: 'Nouveau mot de passe', type: 'password' },
  confirmationPassword: {
    value: '', name: 'confirmationPassword', label: 'Confirmer nouveau le mot de passe', type: 'password',
  },
  referrerCode: {
    value: referrerCode, name: 'referrerCode', label: 'Code parrain', isAvailable: false, isRequired: true,
  },
});

class Infos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infosForm: getInfosInputs(),
      isInfosFormOpened: false,
      isSharePopupOpened: false,
    };
    this.initializeInfosForm = this.initializeInfosForm.bind(this);
    this.setInfosInputValue = this.setInfosInputValue.bind(this);
    this.checkInfosInputValue = this.checkInfosInputValue.bind(this);
    this.submitInfos = this.submitInfos.bind(this);
    this.setBirthday = this.setBirthday.bind(this);
    this.checkIsDone = this.checkIsDone.bind(this);
  }

  componentDidMount() { this.initializeInfosForm(); }

  componentDidUpdate(prevProps) {
    const prevCustomerId = prevProps.customerContext.customer ? prevProps.customerContext.customer._id : undefined;
    const { customerContext: { customer } } = this.props;
    const nowCustomerId = customer ? customer._id : undefined;
    if (prevCustomerId !== nowCustomerId && nowCustomerId !== undefined) {
      this.initializeInfosForm();
    }
  }

  setBirthday(selectedValue, stateName) {
    const infosForm = { ...this.state.infosForm };
    infosForm.birthday[stateName] = selectedValue;
    const { birthday: { date, month, year } } = infosForm;
    infosForm.birthday.isRequired = true;
    if (date !== '' && month !== '' && year !== '') {
      infosForm.birthday.value = moment().set({ year: year.value, month: month.value, date: date.value }).format();
      infosForm.birthday.error = false;
    }
    this.setState({ infosForm: { ...infosForm } });
  }

  setInfosInputValue(target) {
    const { name, value } = target;
    const infosForm = { ...this.state.infosForm };
    infosForm[name].value = value;
    this.setState({ infosForm: { ...infosForm } });
  }

  toggleIsSharePopupOpened() {
    this.setState({ isSharePopupOpened: !this.state.isSharePopupOpened });
  }

  initializeInfosForm() {
    const { customerContext: { customer } } = this.props;
    const firstname = !customer ? '' : customer.firstname;
    const lastname = !customer ? '' : customer.lastname;
    const email = !customer ? '' : customer.email;
    const birthday = !customer ? undefined : customer.birthday;
    const phone = !customer ? '' : customer.phone;
    const referrerCode = !customer ? '' : customer.referrerCode;
    this.setState({
      infosForm: getInfosInputs(firstname, lastname, email, birthday, phone, referrerCode),
    });
  }

  checkInfosInputValue(name) {
    const infosForm = { ...this.state.infosForm };
    if (name.includes('Password')) {
      const isRequired = infosForm.confirmationPassword.value !== '' || infosForm.newPassword.value !== '';
      infosForm.password.isRequired = isRequired;
      infosForm.newPassword.isRequired = isRequired;
      infosForm.confirmationPassword.isRequired = isRequired;
      const isPasswordSameThanConfirmation = infosForm.confirmationPassword.value === infosForm.newPassword.value;
      infosForm[name].error = infosForm[name].value === '';
      if (!isRequired) {
        infosForm.newPassword.error = undefined;
        infosForm.confirmationPassword.error = undefined;
        infosForm.password.error = infosForm.password.error === false ? false : undefined;
      } else if (
        isRequired
        && ((name === 'newPassword'
          && infosForm.confirmationPassword.error !== undefined
          && !isPasswordSameThanConfirmation)
        || (name === 'confirmationPassword'
          && infosForm.newPassword.error !== undefined
          && !isPasswordSameThanConfirmation))
      ) {
        infosForm.confirmationPassword.error = true;
      } else if (name === 'newPassword' && isPasswordSameThanConfirmation) {
        infosForm.confirmationPassword.error = false;
      }
    } else {
      infosForm[name].error = infosForm[name].value === '' || infosForm[name] === undefined;
      if (name === 'password' && !infosForm.password.isRequired) {
        infosForm.password.error = (infosForm.password.value !== '') ? false : undefined;
      }
    }
    this.setState({ infosForm: { ...infosForm } });
  }

  checkIsDone() {
    const infosForm = this.state.infosForm;
    let isInfosFormModified = false;
    let isDone = Object.values(infosForm)
      .reduce((acc, { name, error, value, isRequired }) =>
        acc && (!isRequired
        || (name === 'confirmationPassword' && error === false)
        || (name !== 'confirmationPassword' && value !== ''))
      , true);
    if (infosForm.password.isRequired && infosForm.password.value === '') {
      infosForm.password.error = true;
      isDone = false;
      isInfosFormModified = true;
    }
    if (infosForm.birthday.isRequired && infosForm.birthday.value === '') {
      infosForm.birthday.error = true;
      isDone = false;
      isInfosFormModified = true;
    }
    if (isInfosFormModified) this.setState({ infosForm: { ...infosForm } });
    return isDone;
  }

  submitInfos() {
    if (!this.checkIsDone()) return null;
    const { infosForm: { firstname, lastname, birthday, phone, password, newPassword } } = this.state;
    const infos = {
      firstname: firstname.value,
      lastname: lastname.value,
      phone: phone.value,
    };
    if (birthday.isRequired) infos.birthday = birthday.value;
    if (password.isRequired) infos.password = password.value; infos.newPassword = newPassword.value;
    return callApi('customers/me', 'PUT', { ...infos })
      .then(({ errorPassword }) => {
        const { customerContext: { editCustomerInfos } } = this.props;
        if (errorPassword) {
          const infosForm = { ...this.state.infosForm };
          infosForm.password.value = '';
          infosForm.password.error = true;
          this.setState({ infosForm: { ...infosForm } });
        } else {
          this.setState({ isInfosFormOpened: false });
        }
        if (infos.password) delete infos.firstname;
        editCustomerInfos(infos);
      });
  }

  render() {
    const { infosForm, isInfosFormOpened, isSharePopupOpened } = this.state;
    const { customerContext: { customer } } = this.props;
    const infosSummary = Object.values(infosForm)
      .filter(info => !info.name.includes('error') && !info.name.includes('Password'))
      .map((info) => {
        const newInfo = { ...info };
        if (newInfo.name === 'password') newInfo.value = '*******';
        if (newInfo.name === 'birthday') {
          newInfo.value = newInfo.value === '' ? '' : moment(newInfo.value).format('DD/MM/YYYY');
        }
        return { ...newInfo };
      });
    return (
      <PageContainer>
        {isSharePopupOpened &&
          <PopupShareReferralCode
            closePopup={() => this.toggleIsSharePopupOpened()}
            referralCode={customer.referrerCode}
          />
        }
        <Title marginBottom>
          Mes informations
          {!isInfosFormOpened &&
            <Img
              onClick={() => this.setState({ isInfosFormOpened: true })}
              src={iconEdit}
              alt={'Editer mon profil'}
            />
          }
        </Title>
        {isInfosFormOpened &&
          <InfosForm
            inputsList={infosForm}
            checkFormInputValue={this.checkInfosInputValue}
            setFormInputValue={this.setInfosInputValue}
            setBirthday={this.setBirthday}
            toggleIsSharePopupOpened={() => this.toggleIsSharePopupOpened()}
          />
        }
        {isInfosFormOpened &&
          <Button navy small onClick={this.submitInfos}>Valider</Button>
        }
        {!isInfosFormOpened &&
          infosSummary.map(({ name, label, value }, index) =>
            <SummaryLine key={name} mobileMarginBottom={index === infosSummary.length - 1}>
              <P bold>{label} :</P>
              <P>
                {value}
                {name === 'referrerCode' && <Img
                  large src={iconSend} alt="Partager votre code parrain"
                  onClick={() => this.toggleIsSharePopupOpened()}
                />}
              </P>
            </SummaryLine>,
          )
        }
      </PageContainer>
    );
  }
}

Infos.propTypes = {
  customerContext: PropTypes.shape({
    editCustomerInfos: PropTypes.func,
    customer: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(Infos);
