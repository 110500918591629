import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { navigate } from 'gatsby';


import withAppContext from '../../withAppContext';
import withCustomerContext from '../../withCustomerContext';
import routesMap from '../../Routes';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { pricingInverse } from '../../services/pricing';
import momentFr from '../../tilli-components/src/config/moment.fr';

moment.updateLocale('fr', momentFr);
moment.tz.setDefault('Europe/Paris');

const orderRows = [
  'Date de commande',
  'Statut',
  'Numéro de commande',
  'Réalisation(s)',
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 23px;'}
`;

const Title = styled.div`
  font-family: Roboto;
  font-weight: 500;
  font-size: 26px;
  margin: 20px 0px 41px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px;
    padding-bottom: 13px;
    border-bottom: 0.5px solid ${colors.navy};
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 23px;'}
`;

const Tr = styled.tr`
  border-bottom: 0.5px solid ${({ navy }) => navy ? colors.navy : colors.lightGrey2};
`;

const Th = styled.th`
  font-weight: 500;
  padding: 0px 60px 11px 0px;
  ${({ textLeft }) => textLeft && 'text-align: left;'}
`;

const Td = styled.td`
  color: ${colors.lightGrey4};
  text-align: center;
  padding: 41px 60px 31px 0px;
  ${({ underline }) => underline && 'text-decoration: underline; cursor: pointer;'}
`;

const ClothesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const P = styled.p`
  margin: 0px 0px ${({ marginBottom }) => marginBottom ? '5' : '0'}px;
  ${({ underline }) => underline && 'text-decoration: underline; cursor: pointer;'}
  color: ${colors.lightGrey4};
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${({ marginBottom }) => marginBottom ? '5' : '0'}px;
    margin-left: 7px;
    font-size: 14px;
  }
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ mobileBorder }) => mobileBorder && `
      padding-bottom: 23px;
      border-bottom: 0.5px solid #E5E5E5;
    `}
  }
`;

const OrderLine = styled.div`
  display: flex;
  margin-bottom: 10px;
  ${({ center }) => center && 'justify-content: center;'}
`;

const Heading = styled.p`
  margin: 0px;
  font-weight: 500;
`;

const getStatus = (state) => {
  switch (state) {
    case 'DONE':
    case 'WAITING_PAYMENT_COUTURIER':
      return 'Reçue';
    case 'CANCELED':
      return 'Annulée';
    default:
      return 'En cours';
  }
};

const clothTypesOfWorks = {
  alteration: 'retoucher',
  embroidery: 'broder',
  transformation: 'upcycler',
  creation: 'créer',
};

const getClothName = (cloth) => {
  let clothName = '';
  if (!cloth.name) {
    try {
      clothName = pricingInverse[cloth.items[0].id].cloth;
    } catch (e) {
      clothName = cloth.slug;
    }
  } else {
    clothName = cloth.name;
  }
  if (cloth.typesOfWork?.length > 0) {
    clothName = `${clothName} à ${cloth.typesOfWork
      .reduce((typesOfWork, typeOfWork, index) => {
        let linkWord = '';
        if (index !== cloth.typesOfWork.length - 1) linkWord = ', ';
        if (index === cloth.typesOfWork.length - 2) linkWord = ' et ';
        return (`${typesOfWork}${clothTypesOfWorks[typeOfWork]}${linkWord}`);
      }, '')}`;
  }
  clothName = clothName.replace(' ()', '');
  return clothName;
};

const getOrderValues = (order) => {
  const { createdAt, state, id, clothes } = order;
  const date = moment(createdAt).format('DD/MM/YYYY');
  const status = getStatus(state);
  const orderNumber = id.slice(-6);
  return [date, status, orderNumber, clothes];
};

const selectOrder = (order, setSelectedOrder) => {
  setSelectedOrder({ ...order });
  navigate(routesMap.MyOrderDetails.url);
};

const renderMobileOrder = (order, orderValues, setSelectedOrder) => (
  <Tr key={order.id}>
    {orderValues.map((value) => {
      if (typeof value === 'string') {
        return <Td key={value}>{value}</Td>;
      }
      return (
        <Td key={'clothes'}>
          <ClothesContainer>
            {value.map((cloth, index) => (
              <P key={cloth._id} marginBottom={index !== value.length - 1}>{getClothName(cloth)}</P>
            ))}
          </ClothesContainer>
        </Td>
      );
    })}
    <Td
      underline
      onClick={() => selectOrder(
        { ...order, orderValues, orderRows },
        setSelectedOrder,
      )}
    >
      Voir plus
    </Td>
  </Tr>
);

const renderOrders = (orders, isMobile, showOnlyLastOrder, setSelectedOrder) => {
  const ordersList = showOnlyLastOrder ? [orders[orders.length - 1]] : [...orders];
  ordersList.reverse();
  return (
    ordersList.map((order) => {
      const orderValues = getOrderValues(order);
      if (!isMobile) {
        return renderMobileOrder(order, orderValues, setSelectedOrder);
      }
      return (
        <OrderContainer key={order.id} mobileBorder>
          {orderRows.map((row, index) => (
            <OrderLine key={row}>
              <Heading>{row}</Heading>
              {typeof orderValues[index] === 'string'
                ? <P>{orderValues[index]}</P>
                : <ClothesContainer>
                  {orderValues[index].map(cloth => (
                    <P key={cloth._id || cloth.name}>{getClothName(cloth)}</P>
                  ))}
                </ClothesContainer>
              }
            </OrderLine>
          ))}
          <OrderLine
            center
            onClick={() => setSelectedOrder({ ...order, orderValues, orderRows })}
          >
            <P
              onClick={() => selectOrder(
                { ...order, orderValues, orderRows },
                setSelectedOrder,
              )}
              underline
            >
              Voir plus
            </P>
          </OrderLine>
        </OrderContainer>
      );
    })
  );
};

const renderDesktop = (orders, showOnlyLastOrder, setSelectedOrder) => (
  <Container>
    <Title>{showOnlyLastOrder ? 'Ma dernière commande' : 'Mes commandes'}</Title>
    <Table marginBottom={!showOnlyLastOrder}>
      <thead>
        <Tr navy>
          <Th textLeft={!orders}>{orderRows[0]}</Th>
          <Th textLeft={!orders}>{orderRows[1]}</Th>
          <Th textLeft={!orders}>{orderRows[2]}</Th>
          <Th textLeft={!orders}>{orderRows[3]}</Th>
          <Th />
        </Tr>
      </thead>
      <tbody>
        {orders?.length > 0
          ? renderOrders(orders, false, showOnlyLastOrder, setSelectedOrder)
          : <Tr><Td colSpan="5">Aucune commande effectuée</Td></Tr>}
      </tbody>
    </Table>
  </Container>
);

const renderMobile = (orders, showOnlyLastOrder, setSelectedOrder) => (
  <Container marginBottom={!showOnlyLastOrder}>
    {showOnlyLastOrder && <Title>Ma dernière commande</Title>}
    {orders && orders.length > 0
      ? renderOrders(orders, true, showOnlyLastOrder, setSelectedOrder)
      : <OrderContainer><P marginBottom>Aucune commande effectuée</P></OrderContainer>}
  </Container>
);

const MyOrders = ({
  customerContext: { orders = [], setSelectedOrder },
  context: { isMobile }, showOnlyLastOrder,
}) => {
  const filteredOrders = orders.filter(order => !!order);
  if (!isMobile) return renderDesktop(filteredOrders, showOnlyLastOrder, setSelectedOrder);
  return renderMobile(filteredOrders, showOnlyLastOrder, setSelectedOrder);
};

MyOrders.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  customerContext: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    setSelectedOrder: PropTypes.func,
  }).isRequired,
  showOnlyLastOrder: PropTypes.bool,
};

MyOrders.defaultProps = {
  showOnlyLastOrder: false,
};

export default withAppContext(withCustomerContext(MyOrders));
