import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  mobileThresholdPixels, colors,
  FormInput as FormInputV2,
} from '../home/v3/styledComponents';

import errorLogo from '../../assets/error.png';
import verifiedLogo from '../../assets/verified.png';

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const FormInputContainer = styled.div`
  display: ${({ noDisplay }) => noDisplay ? 'none' : 'flex'};
  flex-direction: column;
  align-items: start;
  color: ${colors.navy};
  width: ${({ size }) => size === 'small' ? '45%' : '100%'};
  margin-bottom: 21px;
`;

const FormInput = styled(FormInputV2)`
  margin: 0px 0px ${({ error }) => error === undefined ? '0' : '-10'}px;
  width: calc(100% - 16px);
  border-radius: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 38px;
  font-family: Roboto;
  font-size: 15px;
  outline: 0;
  border: solid 0.5px ${colors.navy};
  color: ${colors.navy};
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px hsl(1, 100%, 100%) inset;
    -webkit-text-fill-color: ${colors.navy};
  };
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ size }) => size !== 'small' && 'margin-right: 0px;'}
  }
`;

const MoreInfos = styled.p`
  font-family: Roboto;
  line-height: 20px;
  font-size: 14px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 0px;
  ${({ error }) => error && `
    color: ${colors.red};
    margin-top: -15px;
  `}
  @media (max-width: ${mobileThresholdPixels}) {
    line-height: 17px;
    font-size: 12px;
    ${({ error }) => error && `
      margin-top: -15px;
    `}
  }
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const InputAndImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const I = styled.i`
  background-image: ${({ error }) => error ? `url(${errorLogo})` : `url(${verifiedLogo})`};
  background-size: contain;
  width: 10px;
  height: 10px;
  position: relative;
  left: ${({ size }) => size === 'small' ? '90' : '95'}%;
  top: -14px;
  @media (max-width: ${mobileThresholdPixels}) {
    left: ${({ size }) => size === 'small' ? '83' : '92'}%;
  }
`;

const AddressForm = ({ inputsList, checkFormInputValue, setFormInputValue }) => (
  <FormContainer>
    {Object.values(inputsList).map((
      { name, label, isActive, value, type, size, error, more, isUnavailable },
    ) => {
      if (name.includes('error')) {
        return (
          <FormInputContainer noDisplay={!isActive} key={label} size={'large'}>
            <MoreInfos
              error
            >
              {label}
            </MoreInfos>
          </FormInputContainer>
        );
      }
      return (
        <FormInputContainer key={name} size={size}>
          <Label>{label}</Label>
          <InputAndImgContainer>
            <FormInput
              name={name} value={value}
              type={type || 'text'}
              onBlur={() => checkFormInputValue(name)}
              onChange={({ target }) => setFormInputValue(target)}
              error={error}
              disabled={isUnavailable}
            />{error !== undefined && <I size={size} error={error} />}
          </InputAndImgContainer>
          {more &&
            <MoreInfos>{more}</MoreInfos>
          }
        </FormInputContainer>
      );
    })}
  </FormContainer>
);

AddressForm.propTypes = {
  inputsList: PropTypes.shape({}).isRequired,
  checkFormInputValue: PropTypes.func.isRequired,
  setFormInputValue: PropTypes.func.isRequired,
};

export default AddressForm;
