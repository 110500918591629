import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';

import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { Icon } from '../home/v2/styledComponents';
import OutInSideClick from '../home/v2/OutInSideClick';

import sharingSocialMedia from '../../assets/sharingSocialMedia.svg';
import emailIcon from '../../assets/social/email.navy.svg';
import whatsappIcon from '../../assets/social/whatsapp.navy.svg';
import facebookIcon from '../../assets/social/facebook.navy.svg';

const OpacityContainer = styled.div`
  background: ${colors.opacityFilter};
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: visible;
  width: 390px;
  margin: 0 auto;
  padding: 25px 50px 35px;

  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    padding: 22px 12px;
    margin-top: 100px;
    margin-bottom: 25px;
  }
`;

const Img = styled.img`
  width: 68px;
  height: 68px;
  margin: 37px auto 15px;
`;

const A = styled.a`

`;

const Span = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  width: 300px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
`;

const IconsContainer = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  margin: 0px 20px 5px;
  &:hover {
    outline-style: none;
  }
`;

const invitMessages = {
  title: 'Découvre Tilli, le servive de retouches à domicile et gagne 5€ ;)',
  // eslint-disable-next-line max-len
  getEmailBody: (code, url) => `Hello, je viens de tester Tilli, un super service de retouches à domicile. %0D%0ASur l'app Tilli, rentre le code: ${code} sur ${url} et gagne 5€ sur ta première commande ;).`,
  // eslint-disable-next-line max-len
  getFacebookBody: code => `Hello, je viens de tester Tilli, un super service de retouches à domicile. Sur l'app Tilli, rentre le code: ${code} et gagne 5€ sur ta première commande ;)`,
  // eslint-disable-next-line max-len
  getWhatsappBody: code => `😍😍 Je viens de tester Tilli, un super service de retouches à domicile. Sur l'app Tilli, rentre le code: ${code} et gagne 5€ sur ta première commande ;)`,
};


class PopupShareReferralCode extends React.Component {
  getInvitUrl() {
    return `https://tilli.fr/i/?r=${this.props.referralCode}`;
  }

  formatEmail() {
    const body = invitMessages.getEmailBody(this.props.referralCode, this.getInvitUrl());
    return `mailto:?subject=${invitMessages.title}&body=${body}`;
  }

  render() {
    const { referralCode, closePopup } = this.props;
    return (
      <OpacityContainer>
        <OutInSideClick handleClickOutside={() => closePopup()}>
          <Container>
            <Img src={sharingSocialMedia} alt="Partager votre code parrain sur les reseaux sociaux" />
            <Span>Partagez votre code parrainage  avec vos proches !</Span>
            <IconsContainer>
              <A target="_blank" href={this.formatEmail()}>
                <StyledIcon src={emailIcon} alt="Partager votre code parrain par email" />
              </A>

              <WhatsappShareButton
                style={{ outline: 'none' }} title={invitMessages.getWhatsappBody(referralCode)}
                url={this.getInvitUrl()}
              >
                <StyledIcon src={whatsappIcon} alt="Partager votre code parrain sur Twitter" />
              </WhatsappShareButton>

              <FacebookShareButton
                style={{ outline: 'none' }} quote={invitMessages.getFacebookBody(referralCode)}
                url={this.getInvitUrl()}
              >
                <StyledIcon src={facebookIcon} alt="Partager votre code parrain sur Facebook" />
              </FacebookShareButton>
            </IconsContainer>
          </Container>
        </OutInSideClick>
      </OpacityContainer>
    );
  }
}

PopupShareReferralCode.propTypes = {
  closePopup: PropTypes.func.isRequired,
  referralCode: PropTypes.string.isRequired,
};

export default PopupShareReferralCode;
