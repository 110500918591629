import React from 'react';
import styled from 'styled-components';

import { mobileThresholdPixels } from '../home/v3/styledComponents';
import { PageContainer } from './myAccountStyledComponents';
import MyOrders from './MyOrders';
import InfosSummary from './InfosSummary';
import AddressesAndPaymentsSummary from './AddressesAndPaymentsSummary';

const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 97px;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const Summary = () => (
  <PageContainer>
    <MyOrders showOnlyLastOrder />
    <SubContainer>
      <InfosSummary />
      <AddressesAndPaymentsSummary />
    </SubContainer>
  </PageContainer>
);

export default Summary;
